import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import styled from "styled-components"
import Link from "next/link"
import { compact, map, max, minBy, sortBy, sum } from "lodash"
import { useRouter } from "next/router"
import {
  BREAKPOINT_QUERY,
  Button,
  MenuItemCard,
  Spacer,
  Tab,
  TabCategoryMenu,
  useModal,
  text,
  Tooltip,
  Text,
} from "@openui"
import { Icon, IconKey } from "openui/components/Icon"
import dayjs from "@helpers/dayjs"
import { hydrateServerSideProps } from "@state/hydrate"
import { MerchantConfigContext } from "providers/MerchantConfigProvider"
import { formatPhone } from "@helpers/formatPhone"
import { MenuHoursList } from "openui/components/MenuHoursList"
import { MenuCategory } from "@models/menuCategory"
import { useScreenSize } from "hooks/useScreenSize"
import { MenuItem } from "@models/menuItem"
import { useActiveStore } from "hooks/useActiveStore"
import { useActiveMenu } from "hooks/useActiveMenu"
import { usePopularMenuItems } from "hooks/usePopularMenuItems"
import { MenuItemDetailModalFromQuery } from "@components/MenuItemDetail/MenuItemDetailModalFromQuery"
import { useCart } from "hooks/useCart"
import { pluralize } from "@helpers/pluralize"
import { MenuSearch } from "@components/MenuSearch"
import { OrderFallback } from "@components/OrderFallback"

const POPULAR_MENU_ITEM_ID = -1

const ROW_THEME_ITEM_THRESHOLD = 150
const ROW_THEME_CATEGORY_THRESHOLD = 0.6

const getCategoryId = (name: string) => `${name.replaceAll(" ", "_")}`

const Menu = () => {
  const router = useRouter()
  const merchantConfig = useContext(MerchantConfigContext)
  const { activeStore } = useActiveStore()
  const activeMenu = useActiveMenu()
  const popularMenuItems = usePopularMenuItems()
  const { cart } = useCart()
  const { windowWidth, isMobile } = useScreenSize()
  const menuPageRef = useRef<HTMLDivElement | null>(null)
  const menuHeaderCategoryRef = useRef<HTMLDivElement | null>(null)
  const menuCategoryInputRef = useRef<HTMLInputElement | null>(null)
  const menuCategoryRefs = useRef<Record<string, HTMLDivElement>>({})
  const viewItemController = useModal({ onClose: () => router.push("/") })

  const { storeMenuId, menuItemId } = router.query
  const viewItem = (storeMenuId: number, menuItemId: number) => {
    router.push(`/?storeMenuId=${storeMenuId}&menuItemId=${menuItemId}`)
  }
  useEffect(() => {
    if (storeMenuId && menuItemId) {
      viewItemController.open()
    } else {
      viewItemController.close()
    }
  }, [storeMenuId, menuItemId])

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string | null>(
    null,
  )

  const popularMenuItemIndex = useMemo(() => {
    return popularMenuItems.data?.reduce<Record<number, number>>(
      (acc, item, index) => {
        acc[item.id] = index
        return acc
      },
      {},
    )
  }, [popularMenuItems])

  const menuItemsByCategories = useMemo<Array<MenuCategory>>(() => {
    return compact([
      popularMenuItems.data && popularMenuItems.data.length > 0
        ? new MenuCategory(
            POPULAR_MENU_ITEM_ID,
            "Popular Items",
            null,
            -1,
            popularMenuItems.data ?? [],
          )
        : null,
      ...sortBy(
        activeMenu.data?.categories ?? [],
        (categories) => categories.sortOrder,
      ),
    ]).filter((cat) => cat.menuItems.length > 0)
  }, [activeMenu.data?.categories, popularMenuItems])

  const [searchOpen, setSearchOpen] = useState(false)
  const [selectedTabId, setSelectedTabId] = useState<number>(() => {
    return (popularMenuItems.data?.length ?? 0) > 0
      ? -1
      : (sortBy(
          activeMenu.data?.categories ?? [],
          (categories) => categories.sortOrder,
        )?.[0]?.id ?? -1)
  })

  useEffect(() => {
    if (searchOpen) menuCategoryInputRef.current?.focus()
  }, [searchOpen])

  useEffect(() => {
    const selectedCategory = menuItemsByCategories.find(
      (category) => "#" + getCategoryId(category.name) === window.location.hash,
    )
    if (selectedCategory?.id !== undefined) {
      setSelectedTabId(selectedCategory.id)
    }
  }, [])

  useEffect(() => {
    if (!menuPageRef.current) return
    const parentScrollElement = menuPageRef.current.parentElement
    if (!parentScrollElement) return
    const handler: HTMLDivElement["onscroll"] = (e) => {
      const categories: Array<[HTMLDivElement, number]> = Object.values(
        menuCategoryRefs.current,
      ).map((e) => [e, e.getBoundingClientRect().bottom])

      const { scrollTop, clientHeight, scrollHeight } =
        e.target as HTMLDivElement

      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        const newTabId = max(
          map(categories, ([category]) =>
            parseInt(category.getAttribute("data-index") ?? "", 10),
          ),
        )
        if (newTabId === undefined || isNaN(newTabId)) return
        if (newTabId === selectedTabId) return
        setSelectedTabId(newTabId)
        return
      }

      let nextVisibleSection = categories.filter(([, top]) => top >= 0)
      if (nextVisibleSection.length === 0) {
        nextVisibleSection = compact([
          minBy(
            categories.filter(([, t]) => t <= 0),
            ([, t]) => t,
          ),
        ])
      }
      if (nextVisibleSection.length === 0) {
        nextVisibleSection = compact([
          minBy(
            categories.filter(([, t]) => t >= 0),
            ([, t]) => t,
          ),
        ])
      }

      const newTabId = parseInt(
        nextVisibleSection?.[0]?.[0]?.getAttribute("data-index") ?? "",
        10,
      )
      if (newTabId === undefined || isNaN(newTabId)) return
      if (newTabId === selectedTabId) return
      setSelectedTabId(newTabId)
    }

    parentScrollElement.addEventListener("scroll", handler)
    return () => parentScrollElement.removeEventListener("scroll", handler)
  }, [isMobile, selectedTabId])

  const itemHasSearchTerm = useCallback(
    (item: MenuItem) =>
      item.name
        .toLowerCase()
        .includes((debouncedSearchTerm ?? "").toLowerCase()) && !item.isHidden,
    [debouncedSearchTerm],
  )

  const menuItemCategoryTabs = useMemo<Array<Tab>>(
    () => [
      {
        id: "search",
        label: (
          <MenuCategorySearch $active={searchOpen || !!debouncedSearchTerm}>
            <Icon
              iconKey={IconKey.Search}
              size={windowWidth !== 0 ? (isMobile ? 16 : 24) : undefined}
            />
            <MenuSearch
              active={searchOpen || !!debouncedSearchTerm}
              onClose={() => setSearchOpen(false)}
              onSearchTermUpdate={setDebouncedSearchTerm}
            />
          </MenuCategorySearch>
        ),
        onClick: () => setSearchOpen(true),
        active: false,
      },
      ...menuItemsByCategories.map((menuCategory) => ({
        id: menuCategory.name,
        label: menuCategory.name,
        onClick: () => {
          router.push(`#${getCategoryId(menuCategory.name)}`)
        },
      })),
    ],
    [
      searchOpen,
      selectedTabId,
      debouncedSearchTerm,
      windowWidth,
      isMobile,
      menuItemsByCategories,
      router,
    ],
  )
  const activeTabIndex = menuItemsByCategories.findIndex(
    (category) => category.id === selectedTabId,
  )

  const theme = useMemo<"card" | "row">(() => {
    const totalMenuItems = sum(
      activeMenu.data?.categories.map((cat) => cat.menuItems.length),
    )

    const totalMenuItemsWithoutImages = sum(
      activeMenu.data?.categories.map(
        (cat) => cat.menuItems.filter((item) => !item.imageUrl).length,
      ),
    )

    if (
      totalMenuItems > ROW_THEME_ITEM_THRESHOLD ||
      totalMenuItemsWithoutImages / (totalMenuItems || 1) >
        ROW_THEME_CATEGORY_THRESHOLD
    ) {
      return "row"
    }

    return "card"
  }, [activeMenu.data, menuItemsByCategories])

  const itemCount = (cart.data?.orderItems ?? []).reduce(
    (acc, add) => acc + add.quantity,
    0,
  )

  const handleAddMenuCategoryRefs = useCallback((e: HTMLDivElement | null) => {
    if (e) {
      menuCategoryRefs.current[e.id] = e
    }
  }, [])

  const hasActiveMenu = !activeMenu.isPending
    ? !!activeMenu.data && !activeMenu.error
    : null

  if (activeStore?.orderFallbackEnabled) return <OrderFallback />

  return (
    <MenuPage ref={menuPageRef}>
      <MenuHeader>
        <MenuHeaderMobile>
          <MenuHeaderMobileItem>
            <Link href="/locations">
              {activeStore?.name ?? merchantConfig.config?.displayName}
              <Spacer size={1} $vertical />
              <Icon iconKey={IconKey.ChevronDown} />
            </Link>
          </MenuHeaderMobileItem>
          <MenuHeaderMobileItem $textAlign="right">
            {activeMenu.data?.formatMenuHours(
              activeStore?.timezone ?? dayjs.tz.guess(),
              true,
              "short",
            )}
          </MenuHeaderMobileItem>
        </MenuHeaderMobile>
        <MenuHeaderDesktop>
          <MenuStoreInfo>
            <MenuActiveStore>
              Ordering from{" "}
              <Link href="/locations">
                <MenuLink>
                  {activeStore?.name ?? merchantConfig.config?.displayName}
                </MenuLink>
              </Link>
            </MenuActiveStore>

            <MenuAddress>
              <span>{activeStore?.address?.toString()}</span>
              <Link href={`tel:${activeStore?.phone}`}>
                {formatPhone(activeStore?.phone ?? "")}
              </Link>
              <MenuStoreItem>
                {activeMenu.data?.formatMenuHours(
                  activeStore?.timezone ?? dayjs.tz.guess(),
                )}

                {activeMenu.data?.isAvailable ? (
                  <InfoIcon key={activeMenu.data?.id}>
                    <Tooltip
                      content={
                        <MenuHoursList
                          menu={activeMenu.data!}
                          timezone={activeStore?.timezone ?? dayjs.tz.guess()}
                        />
                      }
                      direction="bottom"
                    >
                      <Icon iconKey={IconKey.InfoCircle} />
                    </Tooltip>
                  </InfoIcon>
                ) : null}
              </MenuStoreItem>
            </MenuAddress>
          </MenuStoreInfo>
          {!!activeStore?.coverImageURL && (
            <StoreImage
              alt={activeStore.name}
              src={activeStore.coverImageURL}
            />
          )}
        </MenuHeaderDesktop>
      </MenuHeader>

      {hasActiveMenu === false && (
        <Text style="DisplayHeading/Large">
          No menu is set for this location.
        </Text>
      )}

      {hasActiveMenu === true && (
        <>
          <MenuCategoryHeader ref={menuHeaderCategoryRef}>
            <TabCategoryMenu
              activeTabIndex={activeTabIndex + 1}
              tabs={menuItemCategoryTabs}
            />
          </MenuCategoryHeader>
          <MenuItemSections>
            {menuItemsByCategories
              .filter((menuCategory) =>
                menuCategory.menuItems.some(itemHasSearchTerm),
              )
              .map((menuCategory) => (
                <MenuItemSectionContainer key={menuCategory.id}>
                  <MenuItemSectionHeaderAnchor
                    data-index={menuCategory.id}
                    id={getCategoryId(menuCategory.name)}
                    ref={handleAddMenuCategoryRefs}
                  />
                  <div>
                    <MenuItemSectionHeader data-testid="MenuItemSectionHeader">
                      {menuCategory.name}
                    </MenuItemSectionHeader>
                    <MenuItemSectionRow
                      $isPopularItemRow={
                        menuCategory.id === POPULAR_MENU_ITEM_ID &&
                        menuCategory.menuItems.every((item) => item.imageUrl)
                      }
                    >
                      {menuCategory.menuItems
                        .filter(itemHasSearchTerm)
                        .map((menuItem, index) => (
                          <MenuItemCardWrapper
                            key={menuItem.id + ":" + index}
                            $isAvailable={!!menuItem?.isAvailable}
                            $theme={
                              menuCategory.id !== POPULAR_MENU_ITEM_ID
                                ? theme
                                : "card"
                            }
                            $numberOfItems={menuCategory.menuItems.length}
                            onClick={
                              !menuItem?.isAvailable
                                ? undefined
                                : () => {
                                    viewItem(menuItem.storeMenuId, menuItem.id)
                                  }
                            }
                          >
                            <MenuItemCard
                              theme={
                                menuCategory.id !== POPULAR_MENU_ITEM_ID
                                  ? theme
                                  : "card"
                              }
                              imageUrl={
                                menuItem.imageUrl ??
                                (theme === "card" ||
                                menuCategory.id === POPULAR_MENU_ITEM_ID
                                  ? merchantConfig.config?.itemPlaceholderUrl
                                  : null)
                              }
                              aspectRatio={"1.618033"}
                              badge={
                                popularMenuItemIndex?.[menuItem.id] !==
                                undefined
                                  ? `#${(popularMenuItemIndex?.[menuItem.id] ?? 0) + 1} Most Liked`
                                  : undefined
                              }
                              name={menuItem.name}
                              description={menuItem.description}
                              priceCents={menuItem.priceCents}
                              responsive={
                                menuCategory.id !== POPULAR_MENU_ITEM_ID
                              }
                            />
                          </MenuItemCardWrapper>
                        ))}
                    </MenuItemSectionRow>
                  </div>
                </MenuItemSectionContainer>
              ))}
          </MenuItemSections>
          <MenuItemDetailModalFromQuery
            controller={viewItemController}
            onAddItemToCart={() => router.push("/")}
          />
          {!!cart.data?.orderItems.length && (
            <FloatingCartButton href="/cart">
              <Button width="100%">
                View Cart • {itemCount}{" "}
                {pluralize(
                  {
                    plural: "items",
                    singular: "item",
                  },
                  cart.data?.orderItems.length ?? 0,
                )}
              </Button>
            </FloatingCartButton>
          )}
        </>
      )}
    </MenuPage>
  )
}

export const getServerSideProps = hydrateServerSideProps()

export default Menu

const MenuPage = styled.main`
  display: flex;
  flex-direction: column;
  padding: 20px 62px;

  ${BREAKPOINT_QUERY["mobile"](`
      padding: 0;
  `)}
`

const MenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 228px;
  color: ${({ theme }) => theme.colors["Content/Primary"]};

  ${BREAKPOINT_QUERY["mobile"](`
      height: auto;
  `)}
`
const MenuHeaderDesktop = styled.div`
  display: flex;
  height: 100%;
  z-index: 11;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${BREAKPOINT_QUERY["mobile"](`
      display: none;
  `)}
`

const MenuHeaderMobile = styled.div`
  display: none;

  ${BREAKPOINT_QUERY["mobile"](`
      display: flex;
      padding: 12px 12px 6px;
      justify-content: space-between;
  `)}
`

const MenuActiveStore = styled.div`
  ${text("Body/Regular")}
  font-size: 32px;

  line-height: 42px;

  & a {
    color: unset;
  }
`
const MenuLink = styled.div`
  color: unset;
  word-wrap: wrap;
`
const MenuAddress = styled.address`
  display: flex;
  flex-direction: column;
  color: unset;
  row-gap: 4px;

  ${text("Body/Regular")}

  & > span {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }
`
const MenuStoreInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  align-items: center;
  width: 100%;
`
const MenuStoreItem = styled.div`
  display: inline-flex;
  align-items: center;
`
const StoreImage = styled.img`
  height: auto;
  width: fit-content;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 16px;
  max-height: calc(100% - 32px);

  overflow: hidden;
  width: min(100%, 400px);
  height: auto;

  visibility: ${({ src }) => (src ? "visible" : "hidden")};
`
const InfoIcon = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  cursor: help;
`

const MenuHeaderMobileItem = styled.div<{ $textAlign?: "right" | "left" }>`
  display: none;
  ${text("Body/Regular")}
  color: ${({ theme }) => theme.colors["Content/Primary"]};
  cursor: pointer;
  text-align: ${({ $textAlign = "left" }) => $textAlign};

  ${({ theme }) =>
    BREAKPOINT_QUERY["mobile"](`
      display: inline-flex;
      align-items: center;

      justify-content: center;
      white-space: pre-line;

      a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        margin-right: 8px;

        &,
        &:active {
          color: ${theme.colors["Content/Primary"]};
      }
  `)}
`

const MenuCategoryHeader = styled.div`
  position: -webkit-sticky;
  position: sticky;
  align-self: flex-start;
  top: 0;
  background: ${({ theme }) => theme.colors["Background/Primary"]};
  z-index: 10;

  width: 100%;

  ${BREAKPOINT_QUERY["mobile"](`
      padding: 12px 8px 0px 8px;
  `)}
`

const MenuCategorySearch = styled.div<{ $active: boolean }>`
  display: flex;
  padding: 8px 0px 8px 8px;
  border-radius: 8px;
  align-items: center;

  ${text("Body/Regular")}

  transition:
    background-color 400ms ease,
    opacity 250ms ease-in;

  ${({ $active }) =>
    $active
      ? `
      background-color: #f5f5f5;

      &:hover {
        background-color: #f3efef;
      }`
      : ``}
`

const MenuItemSections = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 8px 80px;
`

const MenuItemSectionHeaderAnchor = styled.div`
  position: absolute;

  top: -60px;
`

const MenuItemSectionHeader = styled.h4`
  display: flex;
  flex-direction: column;

  ${text("Body/Bold")}
  font-size: 20px;

  padding: 18px 0px 12px 0px;
  margin-bottom: 24px;
  border-bottom: solid ${({ theme }) => theme.colors["Content/Primary"]} 2px;
`

const MenuItemSectionRow = styled.ul<{ $isPopularItemRow: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 16px;
  list-style: none;

  padding-bottom: 24px;

  ${({ $isPopularItemRow }) => `
     ${BREAKPOINT_QUERY["mobile"](
       $isPopularItemRow
         ? `
          width: 100%;
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow: auto;
          scroll-behavior: smooth;
          padding-right: 24px;
      `
         : ``,
     )}
  `}
`

const MenuItemCardWrapper = styled.li<{
  $theme: "card" | "row"
  $isAvailable: boolean
  $numberOfItems: number
}>`
  cursor: pointer;
  height: auto;
  margin-right: 16px;

  ${({ $isAvailable }) =>
    !$isAvailable
      ? `
    opacity: 0.5;
    cursor: not-allowed;`
      : ``}

  ${() => `
    a {
      text-decoration: none;

      &,
      &:active {
        all: unset;
      }
    }
  `}

  ${({ $theme, $numberOfItems }) => {
    switch ($theme) {
      case "card": {
        return `
          ${BREAKPOINT_QUERY["mobile"](`
            display: inline-flex;
            min-width: 75vw;
            width: ${$numberOfItems > 1 ? "75vw" : "100vw"};
          `)};

            width: calc(50% - 16px);

            @media (min-width: 837px) {
              width: calc(33% - 16px);
            }

            @media (min-width: 1000px) {
              width: calc(25% - 16px);
            }
        `
      }
      case "row": {
        return `
          @media (max-width: 850px) {
            width: 100%;
          }

 
          width: calc(33% - 16px);
        `
      }
    }
  }}
`

const FloatingCartButton = styled(Link)`
  display: none;

  position: absolute;
  cursor: pointer;

  text-decoration: none;

  width: 100%;
  bottom: 21px;
  padding: 0px 16px;

  ${BREAKPOINT_QUERY["mobile"](`
        display: block;
  `)};
`

const MenuItemSectionContainer = styled.div`
  position: relative;
`
